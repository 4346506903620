import classNames from "classnames";
import React, { TextareaHTMLAttributes } from "react";
import { generateRandomNumber } from "../utils";
import ErrorMessage from "./ErrorMessage";
import InfoTooltip from "./InfoTooltip";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  label?: any;
  errorMessage?: string;
  errorPosition?: "top-right" | "top-left" | "bottom-right" | "bottom-left";
  tooltip?: string;
  className?: string;
  fontMono?: boolean;
  rows?: number;
  maxLength?: number;
  showCharacterCount?: boolean;
  width?: string | number;
  maxHeight?: string | number;
}

const TextArea = ({
  id,
  label,
  errorMessage,
  errorPosition = "top-right",
  tooltip,
  className,
  fontMono,
  rows = 4,
  maxLength,
  showCharacterCount,
  maxHeight,
  width,
  ...rest
}: TextAreaProps) => {
  const textareaId = id ?? `textarea-${generateRandomNumber(6)}`;
  const textareaName = rest.name ?? `textarea-${generateRandomNumber(6)}`;
  const currentLength = typeof rest.value === "string" ? rest.value.length : 0;

  return (
    <div
      className={classNames("w-full", className)}
      style={{ width: width ?? "100%" }}
    >
      <div className="sm:col-span-3">
        {label && (
          <label
            htmlFor={textareaId}
            className="mb-1 flex justify-between uppercase text-xs tracking-wide font-medium leading-6 text-gray-900"
          >
            <div className="flex items-center gap-2">
              <div className="flex">
                {label}
                {rest.required && (
                  <span className="text-red-500 align-super ml-0.5">*</span>
                )}
              </div>
              {tooltip && (
                <InfoTooltip
                  id={`${textareaId}-tooltip`}
                  tooltip={tooltip}
                />
              )}
            </div>
            {errorMessage && errorPosition === "top-right" && (
              <ErrorMessage
                errorMessage={errorMessage}
                name={textareaName}
              />
            )}
          </label>
        )}
        <div className="relative mt-2">
          <textarea
            {...rest}
            value={rest.value || ""}
            id={textareaId}
            name={textareaName}
            rows={rows}
            maxLength={maxLength}
            className={classNames(
              "rounded-md text-gray-900 block w-full border-0 py-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 disabled:placeholder:opacity-0 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6",
              {
                "ring-red-500": errorMessage,
                "font-mono": fontMono,
              },
              "disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:ring-gray-200",
              "resize-y min-h-[80px]"
            )}
            style={{ maxHeight: maxHeight ?? "none" }}
          />
        </div>
        <div className="flex justify-between mt-1">
          {errorMessage && errorPosition === "bottom-left" && (
            <ErrorMessage
              errorMessage={errorMessage}
              name={textareaName}
            />
          )}
          {showCharacterCount && (
            <div className="ml-auto text-xs text-gray-500">
              {currentLength}
              {maxLength ? `/${maxLength}` : ""}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextArea;
