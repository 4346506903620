import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, {
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  useState,
} from "react";
import { generateRandomNumber } from "../utils";
import Button from "./Button";
import ErrorMessage from "./ErrorMessage";
import InfoTooltip from "./InfoTooltip";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: any;
  errorMessage?: string;
  errorPosition?: "top-right" | "top-left" | "bottom-right" | "bottom-left";
  tooltip?: string;
  className?: string;
  fontMono?: boolean;
}

const Input = ({
  id,
  type = "text",
  label,
  errorMessage,
  errorPosition = "top-right",
  tooltip,
  className,
  fontMono,
  ...rest
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputType: HTMLInputTypeAttribute = showPassword ? "text" : type;
  const inputId = id ?? `input-${generateRandomNumber(6)}`;
  const inputName = rest.name ?? `input-${generateRandomNumber(6)}`;
  return (
    <div className={classNames("w-full", className)}>
      <div className="sm:col-span-3">
        {label && (
          <label
            htmlFor={inputId}
            className="mb-1 flex justify-between uppercase text-xs tracking-wide font-medium leading-6 text-gray-900"
          >
            <div className="flex items-center gap-2">
              <div className="flex">
                {label}
                {rest.required && (
                  <span className="text-red-500 align-super ml-0.5">*</span>
                )}
              </div>
              {tooltip && (
                <InfoTooltip
                  id={`${inputId}-tooltip`}
                  tooltip={tooltip}
                />
              )}
            </div>
            {errorMessage && errorPosition === "top-right" && (
              <ErrorMessage
                errorMessage={errorMessage}
                name={inputName}
              />
            )}
          </label>
        )}
        <div className="relative mt-2 flex items-center rounded-md">
          <input
            {...rest}
            value={rest.value || ""}
            type={inputType}
            id={id ?? inputName}
            name={inputName}
            autoComplete={inputName}
            className={classNames(
              "rounded-md text-gray-900 block w-full border-0 py-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6",
              {
                "ring-red-500": errorMessage,
              },
              {
                "font-mono": fontMono,
              },
              "disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:ring-gray-200 disabled:placeholder:opacity-0"
            )}
          />
          {type === "password" && (
            <Button
              variant="transparent"
              className="absolute inset-y-0 right-0 group flex items-center justify-center min-w-[2.5rem]"
              onClick={(e: any) => {
                e.stopPropagation();
                setShowPassword((prevShowPassword) => !prevShowPassword);
              }}
            >
              {!showPassword && (
                <EyeSlashIcon className="w-4 h-4 text-gray-500 group-hover:text-gray-700" />
              )}
              {showPassword && (
                <EyeIcon className="w-4 h-4 text-gray-500 group-hover:text-gray-700" />
              )}
            </Button>
          )}
        </div>
        {errorMessage && errorPosition === "bottom-left" && (
          <ErrorMessage
            errorMessage={errorMessage}
            name={inputName}
          />
        )}
      </div>
    </div>
  );
};

export default Input;
