import { FormikErrors } from "formik";
import React from "react";
import Input from "../../../components/Input";
import UploadMedia from "../../../components/UploadMedia";
import { SourceEnum } from "../../../enums/template";
import { Template } from "../../../models";
import {
  getCurrentTime,
  getMediaLinkPlaceholder,
  getMediaLinkTooltip,
  identifyUrlSource,
} from "../../../utils";
import { AutomationSetup } from "./AutomationSetup";
import PlatformSelector from "./PlatformSelector";
import SwitchContainer from "./common/SwitchContainer";
import TemplateContainer from "./common/TemplateContainer";

type SourceNAutomationProps = {
  values: Partial<Template>;
  errors: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<any>>;
  setErrors: any;
};

const SourceNAutomation = ({
  values,
  errors,
  setFieldValue,
  setErrors,
}: SourceNAutomationProps) => {
  const isAutoEnabled = !!values?.is_auto_enabled;
  const isUpload = values?.source_id === SourceEnum.UPLOAD_MEDIA;
  const handlePlatformChange = (sourceId: SourceEnum) => {
    setFieldValue("source_id", sourceId);
  };

  return (
    <div className="animate-fadeIn space-y-6">
      <TemplateContainer>
        <div className="space-y-6">
          <PlatformSelector
            source_id={values?.source_id!}
            handlePlatformChange={handlePlatformChange}
          />
          {!isUpload ? (
            <>
              <SwitchContainer
                title="Enable Automation"
                subTitle="Auto generate articles from your profile."
                enabled={isAutoEnabled}
                setEnabled={() => {
                  setFieldValue("is_auto_enabled", !isAutoEnabled);
                  if (!isAutoEnabled) {
                    setFieldValue("auto_days", [1, 2, 3, 4, 5]);
                    setFieldValue("auto_time1", getCurrentTime());
                  } else {
                    setFieldValue("auto_days", []);
                    setFieldValue("auto_time1", null);
                    setFieldValue("auto_time2", null);
                  }
                }}
              />
              {/* Media Link or Profile Link */}
              {!isAutoEnabled && (
                <div className="relative">
                  <Input
                    label="Media link"
                    name="media_url"
                    value={values?.media_url || ""}
                    errorMessage={errors?.media_url as string}
                    errorPosition="bottom-left"
                    placeholder={getMediaLinkPlaceholder(values?.source_id!)}
                    tooltip={getMediaLinkTooltip(values?.source_id!)}
                    onChange={({ target: { value } }) => {
                      setFieldValue("media_url", value.trim());
                    }}
                    onBlur={({ target: { value } }) => {
                      const url = value.trim();
                      if (url) {
                        const source_id = identifyUrlSource(url);
                        setFieldValue("source_id", source_id);
                      }
                    }}
                  />
                </div>
              )}
              {isAutoEnabled && (
                <AutomationSetup
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              )}
            </>
          ) : (
            <UploadMedia
              url={values.upload_media_url || ""}
              error={errors?.upload_media_url as string}
              onUpload={(value) => {
                setFieldValue("upload_media_url", value);
                // handle resetting auto fields as UPLOAD is one-off
                if (values?.is_auto_enabled) {
                  setFieldValue("is_auto_enabled", false);
                  setFieldValue("auto_days", []);
                  setFieldValue("username", null);
                  setFieldValue("auto_time1", null);
                  setFieldValue("auto_time2", null);
                  setFieldValue("external_user_id", null);
                }
              }}
              onError={(message: string) =>
                setErrors({ upload_media_url: message })
              }
            />
          )}
        </div>
      </TemplateContainer>
    </div>
  );
};

export default SourceNAutomation;
