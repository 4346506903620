import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { AutomationDaysEnum, AutomationDaysMap } from "../../../enums/template";
import { formatTimeHHMM } from "../../../utils";

interface IsAutoEnabledProps {
  isAutoEnabled: boolean;
  autoDays: number[];
  autoTime1: string;
  autoTime2: string;
  timezone: string;
}

const IsAutoEnabled = ({
  isAutoEnabled,
  autoDays,
  autoTime1,
  autoTime2,
  timezone,
}: IsAutoEnabledProps) => {
  if (!isAutoEnabled) {
    return <XMarkIcon className="size-4 text-red-600" />;
  }
  return (
    <div className="truncate overflow-hidden text-sm">
      {autoDays?.length !== 0 && (
        <div>
          {autoDays.map((day: AutomationDaysEnum, index: number) => {
            return (
              <span key={index}>
                {AutomationDaysMap[day]}
                {index !== autoDays?.length - 1 && ", "}
              </span>
            );
          })}
          {" @ " + formatTimeHHMM(autoTime1)}
          {autoTime2 ? ", " + formatTimeHHMM(autoTime2) : null}
        </div>
      )}
    </div>
  );
};

export default IsAutoEnabled;
