import React from "react";
import Input from "../../../components/Input";
import {
  FaqQuestionPrefixEnum,
  ImageSizeEnum,
  NoOfFaqEnum,
  SourceEnum,
} from "../../../enums/template";
import { Template } from "../../../models";
import PointOfView from "./PointOfView";
import SourceNAutomation from "./SourceNAutomation";
import SwitchContainer from "./common/SwitchContainer";
import TemplateContainer from "./common/TemplateContainer";

type SetupProps = {
  values: Template;
  errors: any;
  setFieldValue: any;
  setErrors: any;
};

const Setup = ({ values, errors, setFieldValue, setErrors }: SetupProps) => {
  const includeImage = !!values?.include_image;
  const isUpload = values?.source_id === SourceEnum.UPLOAD_MEDIA;
  const includeFaq = Boolean(values?.include_faq);
  return (
    <div className="animate-fadeIn space-y-6 ">
      <TemplateContainer>
        <Input
          label="Template Name"
          name="name"
          errorMessage={errors["name"] as string}
          errorPosition="bottom-left"
          value={values?.name}
          placeholder="A meaningful name for this article group. Templates can generate one or more articles"
          tooltip="From this template your articles will be generated. Provide a meaningful name, for example, Achieve Personal Growth or Home Design Tips."
          onChange={({ target: { value } }) => setFieldValue("name", value)}
          onBlur={({ target: { value } }) => {
            setFieldValue("name", value.trim());
          }}
        />
      </TemplateContainer>
      <SourceNAutomation
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        setErrors={setErrors}
      />
      <TemplateContainer className="space-y-8">
        <SwitchContainer
          title="Include Image"
          subTitle="Add an image to the article."
          tooltip="A unique, non-stock image will be generated based on the content of the article."
          enabled={includeImage}
          setEnabled={() => {
            setFieldValue("include_image", !includeImage);
            if (!includeImage && !values.image_size_id) {
              setFieldValue("image_size_id", ImageSizeEnum.LANDSCAPE);
            }
          }}
        />
        {!isUpload && (
          <SwitchContainer
            title="Embed the video into the Article"
            subTitle=""
            tooltip="This will embed the video right into the article."
            enabled={!!values?.include_web_embed}
            setEnabled={() =>
              setFieldValue("include_web_embed", !values?.include_web_embed)
            }
          />
        )}
        <div>
          <SwitchContainer
            title="Include FAQs"
            tooltip='Add a "Frequently Asked Questions" section to the bottom of the article.'
            enabled={includeFaq}
            setEnabled={() => {
              setFieldValue("include_faq", !values?.include_faq);
              if (!values?.include_faq && !values.faq_question_prefix_id) {
                setFieldValue(
                  "faq_question_prefix_id",
                  FaqQuestionPrefixEnum.Q_LETTER
                );
              }
              if (!values?.include_faq && !values.faq_count) {
                setFieldValue("faq_count", NoOfFaqEnum.THREE);
              }
            }}
          />
        </div>
        <PointOfView {...{ values, setFieldValue }} />
      </TemplateContainer>
    </div>
  );
};

export default Setup;
