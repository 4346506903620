import React from "react";
import SelectV2, { OptionType } from "../../../components/CustomSelect";
import { ArticleTypeEnum } from "../../../enums/template";
import { Template } from "../../../models";

const articleTypeOptions: OptionType[] = [
  {
    value: ArticleTypeEnum.NONE,
    label: "None",
    description: "",
  },
  {
    value: ArticleTypeEnum.SUMMARY,
    label: "Summary",
    description: "Condenses main points of a topic for quick understanding.",
  },
  {
    value: ArticleTypeEnum.LONG_SUMMARY,
    label: "Long Summary",
    description: "Detailed recap covering key insights and essential points.",
  },
  {
    value: ArticleTypeEnum.NEWS,
    label: "News",
    description:
      "Reports on recent developments with clear, factual information.",
  },
  {
    value: ArticleTypeEnum.LISTICLE,
    label: "Listicle",
    description:
      "Information presented as an easy-to-read bulleted or numbered list.",
  },
  {
    value: ArticleTypeEnum.TUTORIAL,
    label: "Tutorial",
    description: "Step-by-step guide for completing a task or skill.",
  },
  {
    value: ArticleTypeEnum.OPINION,
    label: "Opinion",
    description: "Personal perspective on a topic with unique insights.",
  },
  {
    value: ArticleTypeEnum.INTERVIEW,
    label: "Interview",
    description:
      "Conversation with an expert or influencer, sharing their insights.",
  },
  {
    value: ArticleTypeEnum.ACTION_STEPS,
    label: "Action Steps",
    description: "Specific steps readers can take to achieve a result.",
  },
  {
    value: ArticleTypeEnum.PERSONAL_STORIES,
    label: "Personal Stories",
    description: "Brief, personal story illustrating a key point.",
  },
  {
    value: ArticleTypeEnum.PRODUCT_REVIEW,
    label: "Product Review",
    description: "Evaluation of a product's features and overall value.",
  },
  {
    value: ArticleTypeEnum.GUIDE,
    label: "Guide",
    description: "In-depth, comprehensive resource on a specific topic.",
  },
  {
    value: ArticleTypeEnum.CASE_STUDY,
    label: "Case Study",
    description: "Real-world example showing a solution and its results.",
  },
];

type ArticleTypeProps = {
  values: Partial<Template>;
  articleTypeId: ArticleTypeEnum;
  onSelect: (articleTypeId: ArticleTypeEnum) => void;
};

const ArticleType = ({ values, articleTypeId, onSelect }: ArticleTypeProps) => {
  return (
    <div className="mb-2">
      <SelectV2
        label="Article Type"
        placeholder="-"
        options={articleTypeOptions}
        selected={articleTypeOptions.find(
          (option) => option.value === articleTypeId
        )}
        tooltip="Each article type uses distinct structures, tones, and objectives to enhance readability and achieve engagement."
        setSelected={(option) => {
          if (!option) {
            onSelect(ArticleTypeEnum.NONE);
          } else {
            onSelect(option?.value as ArticleTypeEnum);
          }
        }}
      />
    </div>
  );
};

export default ArticleType;
