import classNames from "classnames";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Template } from "../../models/template";
import {
  browserTimeZone,
  convertReelsToReelInURL,
  getInvalidMediaLinkErrorMessage,
  isValidMediaLink,
  removeURLQueryParams,
  removeURLQueryParamsExceptForV,
} from "../../utils";
import Setup from "./components/Setup";

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  AdjustmentsHorizontalIcon,
  ArrowUpOnSquareIcon,
  Cog6ToothIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { useSearchParam } from "react-use";
import Button from "../../components/Button";
import { ROUTE_TEMPLATES } from "../../constants/routes";
import SpringContainer from "../../containers/SpringContainer";
import {
  ArticleTypeEnum,
  PointOfViewEnum,
  SourceEnum,
  TemplateStatusEnum,
  TranscribeSubjectEnum,
} from "../../enums/template";
import { setUpgradeModal } from "../../redux/features/userSlice";
import { useAppDispatch } from "../../redux/hooks";
import { useGetDashboardQuery } from "../../redux/services/dashboard";
import {
  useCreateTemplateMutation,
  useGetTemplateByIdQuery,
  useUpdateTemplateByIdMutation,
} from "../../redux/services/templates";
import { useGetUserQuery } from "../../redux/services/user";
import AdvancedSettings from "./components/AdvancedSettings";
import DestinationSettings from "./components/DestinationSettings";
import SideNav from "./components/SideNav";

export const enableEdit = (
  templateId: string | undefined,
  status: TemplateStatusEnum | undefined
) => {
  return !templateId || status === TemplateStatusEnum.DRAFT;
};

export enum TemplateStepEnum {
  SETUP = "setup",
  ADVANCED_SETTINGS = "advanced-settings",
  DESTINATION = "destination",
}

export const TemplateSteps = [
  {
    name: TemplateStepEnum.SETUP,
    title: "Setup",
    icon: <AdjustmentsHorizontalIcon className="w-5 mr-1" />,
  },
  {
    name: TemplateStepEnum.ADVANCED_SETTINGS,
    title: "Advanced Settings",
    icon: <Cog6ToothIcon className="w-5 mr-1" />,
  },
  {
    name: TemplateStepEnum.DESTINATION,
    title: "Destination",
    icon: <ArrowUpOnSquareIcon className="w-5 mr-1" />,
  },
];

const initialValues: Partial<Template> = {
  // base
  name: "",
  source_id: SourceEnum.INSTAGRAM_REELS,
  template_status_id: TemplateStatusEnum.PENDING,
  // completed_at: ""

  // auto
  is_auto_enabled: false,
  profile_url: null, // if not username, (unused)
  auto_days: [],
  auto_time1: null,
  auto_time2: null,
  timezone: "", // browser

  // base prompt
  media_url: "",
  upload_media_url: "",
  media_author_name: "",
  bio: "", // user.bio
  include_intro: true,
  article_length: null,
  temperature: 0.5,
  point_of_view_id: PointOfViewEnum.THIRD_PERSON,
  include_web_embed: true,
  include_credits: false,

  // brand
  brand_persona_id: null,
  brand_voice_id: null,
  brand_tone_id: null,
  transcribe_subject_id: TranscribeSubjectEnum.GENERAL,

  // image
  include_image: false,
  image_size_id: null,
  image_style_id: null,
  image_tone_id: null,

  // FAQ
  include_faq: false,
  faq_count: null,
  faq_question_prefix_id: null,

  // Destination
  destination_id_list: null,
  destination_id: null,

  // Article Type
  article_type_id: ArticleTypeEnum.NONE,

  // engine
  engine_id: null,
};

const TemplateCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { id: templateId } = useParams(); // EDIT
  const redirectToStep = useSearchParam("redirect_to_step");
  const selectedAccount = useSelector(
    (state: any) => state.user.selectedAccount
  );
  const accountId = selectedAccount?.account_id;
  const { data: user } = useGetUserQuery();
  // data queries
  const { data: template, refetch } = useGetTemplateByIdQuery(
    {
      account_id: accountId,
      template_id: templateId,
    },
    { skip: !templateId }
  );
  const [
    createTemplate,
    {
      isLoading: loadingCreate,
      isSuccess: isSuccessCreate,
      isError: isErrorCreate,
      error: errorCreate,
    },
  ] = useCreateTemplateMutation(); // CREATE
  const [
    updateTemplateById,
    {
      isLoading: loadingEdit,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
    },
  ] = useUpdateTemplateByIdMutation(); // EDIT
  const { data: dashboardData } = useGetDashboardQuery(
    {
      account_id: accountId || "",
      timezone: selectedAccount?.timezone || browserTimeZone,
    },
    { skip: !accountId }
  );
  // state
  const [step, setStep] = useState(TemplateStepEnum.SETUP);
  const [formValues, setFormValues] = useState<Template>();

  useEffect(() => {
    // refetch template data
    if (templateId) {
      refetch();
    }
  }, [templateId, refetch]);

  useEffect(() => {
    if (template) {
      // EDIT
      let values = {
        ...template,
        destination_id:
          template?.destination_id || template?.destination_id_list?.[0],
      };
      if (template.source_id === SourceEnum.UPLOAD_MEDIA) {
        values["upload_media_url"] = template.media_url;
        values["media_url"] = null;
      }
      setFormValues(new Template(values));
    } else {
      // CREATE
      setFormValues(
        new Template({
          ...initialValues,
          bio: user?.bio || `${user?.first_name} ${user?.last_name}` || "",
          transcribe_subject_id: TranscribeSubjectEnum.GENERAL,
          timezone: user?.accounts?.[0]?.timezone || browserTimeZone,
        })
      );
    }
  }, [template, user]);

  useEffect(() => {
    if (redirectToStep && redirectToStep === TemplateStepEnum.DESTINATION) {
      setStep(TemplateStepEnum.DESTINATION);
    }
  }, [redirectToStep]);

  useEffect(() => {
    if (isSuccessCreate || isSuccessUpdate) {
      toast.success(
        `Successfully ${isSuccessCreate ? "created" : "updated"} template.`
      );
      navigate(ROUTE_TEMPLATES);
    }
  }, [isSuccessCreate, isSuccessUpdate, navigate]);

  useEffect(() => {
    if (isErrorCreate || isErrorUpdate || errorCreate || errorUpdate) {
      toast.error(
        ((errorCreate || errorUpdate) as any)?.data?.detail ||
          "Something went wrong! Please try again."
      );
    }
  }, [isErrorCreate, isErrorUpdate, errorCreate, errorUpdate]);

  const cleanMediaUrl = (
    url: string | null | undefined,
    sourceId: SourceEnum | undefined
  ) => {
    if (!url) return null;

    if (sourceId === SourceEnum.INSTAGRAM_REELS) {
      let cleanUrl = url;
      if (cleanUrl.includes("?")) {
        cleanUrl = removeURLQueryParams(cleanUrl);
      }
      if (cleanUrl.includes("reels")) {
        cleanUrl = convertReelsToReelInURL(cleanUrl);
      }
      return cleanUrl;
    }

    if (sourceId === SourceEnum.YOUTUBE_VIDEOS && url.includes("?")) {
      return removeURLQueryParamsExceptForV(url);
    }

    return url;
  };

  const preparePayload = (values: Partial<Template>, accountId: string) => {
    let payload = {
      account_id: accountId,
      ...values,
    };

    // Remove auto / manual fields
    if (payload.is_auto_enabled) {
      payload.media_url = null;
      if (payload.source_id !== SourceEnum.INSTAGRAM_REELS) {
        payload.external_user_id = null;
      }
    } else {
      payload.auto_days = [];
      payload.username = null;
      payload.auto_time1 = null;
      payload.auto_time2 = null;
      payload.external_user_id = null;
      payload.media_url = cleanMediaUrl(payload.media_url, payload.source_id);
    }

    // Remove web embed and auto fields for source UPLOAD
    if (payload.source_id === SourceEnum.UPLOAD_MEDIA) {
      payload.include_web_embed = false;
      payload.media_url = payload.upload_media_url;
      payload.auto_days = [];
      payload.username = null;
      payload.auto_time1 = null;
      payload.auto_time2 = null;
    }

    // Reset image settings if disabled
    if (!payload.include_image) {
      payload.image_size_id = null;
      payload.image_style_id = null;
      payload.image_tone_id = null;
    }

    // Reset FAQ settings if disabled
    if (!payload.include_faq) {
      payload.faq_question_prefix_id = null;
      payload.faq_count = null;
    }

    // Remove FIRST_PERSON context fields
    if (values.point_of_view_id === PointOfViewEnum.THIRD_PERSON) {
      payload.is_speaker_the_author = null;
    }

    // Remove unnecessary fields
    delete payload.destination_id_list;

    return payload;
  };

  const handleSubmit = async (values: any) => {
    setFormValues({ ...formValues, ...values });

    let { upload_media_url, ...payload } = preparePayload(values, accountId);

    try {
      if (templateId) {
        // Handle update case
        if (template?.destination_id_list?.[0] !== payload.destination_id) {
          payload.old_destination_id = template.destination_id_list[0];
          payload.destination_id = payload.destination_id || null;
        }
        payload.template_id = templateId;
        await updateTemplateById(payload)
          .unwrap()
          .catch(() => {});
      } else {
        // Handle create case
        await createTemplate(payload)
          .unwrap()
          .catch(() => {});
      }
    } catch (error) {
      // Error handling is already done in useEffect
    }
  };

  return (
    <SpringContainer className="overflow-y-auto overflow-x-hidden">
      <Formik
        enableReinitialize={true}
        initialValues={formValues!}
        validateOnChange={false}
        validate={(values: any) => {
          const errors: any = {};

          // Name validation (always required)
          if (!values.name) {
            errors.name = "Name is required";
          }

          // Username validation (required only if auto-enabled)
          if (values.is_auto_enabled && !values.username) {
            errors.username = "Username is required";
          }

          if (values.source_id === SourceEnum.UPLOAD_MEDIA) {
            if (!values.upload_media_url) {
              errors.upload_media_url = "Audio or video is required";
            }
          }

          // Media validation (skip if auto-enabled)
          if (
            !values.is_auto_enabled &&
            values.source_id !== SourceEnum.UPLOAD_MEDIA
          ) {
            if (!values.media_url) {
              errors.media_url = "Media link is required";
            } else if (!isValidMediaLink(values.media_url, values.source_id)) {
              errors.media_url = getInvalidMediaLinkErrorMessage(
                values.source_id
              );
            }
          }

          return errors;
        }}
        onSubmit={(values) => {
          if (values?.template_status_id === TemplateStatusEnum.QUEUED) {
            return;
          }
          if (dashboardData?.tokens_remaining < 1) {
            dispatch(setUpgradeModal(true));
            return;
          }
          handleSubmit(values);
        }}
      >
        {(props: FormikProps<any>) => {
          const {
            values,
            errors,
            validateForm,
            setFieldValue,
            submitForm,
            setErrors,
          } = props;
          const loading = Boolean(loadingCreate || loadingEdit);
          const isAuto = values?.is_auto_enabled;
          const isQueued =
            values?.template_status_id === TemplateStatusEnum.QUEUED;
          const allowEdit = enableEdit(
            values?.template_id,
            values?.template_status_id
          );
          return (
            <Form>
              <div className="flex flex-col xl:flex-row xl:space-x-6">
                <SideNav {...{ step, setStep, validateForm }} />
                <div
                  className={`w-full ltr:mr-2 rtl:ml-2 mt-10 ${loading ? "opacity-50" : "opacity-100"}`}
                >
                  {step === TemplateStepEnum.SETUP && (
                    <Setup
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      setErrors={setErrors}
                    />
                  )}
                  {step === TemplateStepEnum.ADVANCED_SETTINGS && (
                    <AdvancedSettings
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {step === TemplateStepEnum.DESTINATION && (
                    <DestinationSettings
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  <div className="flex flex-col gap-6 my-6">
                    {step === TemplateStepEnum.ADVANCED_SETTINGS && (
                      <Button
                        paddingZero
                        variant="transparent-primary"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          validateForm().then((formErrors: any) => {
                            if (Object.keys(formErrors).length === 0) {
                              setStep(TemplateStepEnum.DESTINATION);
                            }
                          });
                        }}
                        className={classNames("min-w-24 w-max")}
                      >
                        <span className="flex items-center uppercase text-xs font-semibold tracking-wide">
                          Destination{" "}
                          <ChevronRightIcon className="ml-0.5 text-primary size-4" />
                        </span>
                      </Button>
                    )}
                    {step === TemplateStepEnum.SETUP && (
                      <Button
                        paddingZero
                        variant="transparent-primary"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          validateForm().then((formErrors: any) => {
                            if (Object.keys(formErrors).length === 0) {
                              setStep(TemplateStepEnum.ADVANCED_SETTINGS);
                            }
                          });
                        }}
                        className={classNames("min-w-24 w-max")}
                      >
                        <span className="flex items-center uppercase text-xs font-semibold tracking-wide">
                          Advanced Settings{" "}
                          <ChevronRightIcon className="ml-0.5 text-primary size-4" />
                        </span>
                      </Button>
                    )}
                    {isQueued && (
                      <div className="text-xs text-red-600 uppercase tracking-wide flex gap-1">
                        <ExclamationTriangleIcon className="size-4 text-red-600" />
                        This template is currently generating.
                      </div>
                    )}
                    <div className="flex items-center gap-3">
                      {(isAuto || allowEdit) && (
                        <Button
                          variant={isQueued ? "disabled" : "primary"}
                          onClick={() => {
                            const status = isAuto
                              ? TemplateStatusEnum.PENDING
                              : TemplateStatusEnum.DRAFT;
                            setFieldValue("template_status_id", status);
                            submitForm();
                          }}
                          loading={loading}
                          style={{ minWidth: 120 }}
                        >
                          {isAuto ? "Save" : "Save as Draft"}
                        </Button>
                      )}
                      {!isAuto && (
                        <Button
                          variant={isQueued ? "disabled" : "primary"}
                          onClick={() => {
                            setFieldValue(
                              "template_status_id",
                              TemplateStatusEnum.PENDING
                            );
                            submitForm();
                          }}
                          loading={loading}
                          style={{ minWidth: 150 }}
                        >
                          Generate Now
                        </Button>
                      )}
                      <Button
                        variant="transparent"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          navigate(ROUTE_TEMPLATES);
                        }}
                        loading={loading}
                        style={{ minWidth: 90 }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SpringContainer>
  );
};

export default TemplateCreate;
