export enum SourceEnum {
  INSTAGRAM_REELS = 1,
  YOUTUBE_SHORTS = 2,
  APPLE_PODCASTS = 3,
  SPOTIFY_PODCASTS = 4,
  YOUTUBE_VIDEOS = 5,
  UPLOAD_MEDIA = 6,
}

export enum TemplateStatusEnum {
  DRAFT = 0,
  PENDING = 1,
  QUEUED = 2,
  COMPLETED = 3,
  AUTO = 4,
  PAUSED = 5,
  STOPPED = 6,
  ARCHIVED = 7,
}

export enum BrandPersonaEnum {
  PROFESSIONAL = 1,
  EXPERT = 2,
  PHILOSOPHER = 3,
  FRIEND = 4,
  ENTERTAINER = 5,
  SAGE = 6,
  REBEL = 7,
  CAREGIVER = 8,
}

export enum BrandVoiceEnum {
  PROFESSIONAL = 1,
  CASUAL = 2,
  ENTHUSIASTIC = 3,
  STRAIGHTFORWARD = 4,
  WITTY = 5,
  EMPOWERING = 6,
  INTELLECTUAL = 7,
  MOTIVATIONAL = 8,
}

export enum BrandToneEnum {
  INFORMATIVE = 1,
  PERSUASIVE = 2,
  PLAYFUL = 3,
  FRIENDLY = 4,
  INSPIRING = 5,
  ASPIRATIONAL = 6,
  PASSIONATE = 7,
}

export const enum ImageSizeEnum {
  LANDSCAPE = 1,
  PORTRAIT = 2,
  SQUARE = 3,
}

export const enum ImageStyleEnum {
  MINIMALISTIC = 1,
  GRADIENT = 2,
  REALISTIC = 3,
  ABSTRACT = 4,
  SURREAL = 5,
  VINTAGE = 6,
  FUTURISTIC = 7,
  IMPRESSIONISTIC = 8,
  OIL_PAINTING = 9,
  PIXEL_ART = 10,
  WATERCOLOR = 11,
  POP_ART = 12,
  CARTOON = 13,
}

export const enum ImageToneEnum {
  VIBRANT = 1,
  GRADIENT = 2,
  LIGHT = 3,
  BRIGHT = 4,
  SOFT = 5,
  MOODY = 6,
  VINTAGE = 7,
  EARTHY = 8,
  HIGH_KEY = 9,
  LOW_KEY = 10,
  GRAYSCALE = 11,
}

export enum AutomationDaysEnum {
  MON = 1,
  TUE = 2,
  WED = 3,
  THUR = 4,
  FRI = 5,
  SAT = 6,
  SUN = 7,
}

export enum FaqQuestionPrefixEnum {
  Q_LETTER = 1,
  Q_MARK = 2,
}

export enum NoOfFaqEnum {
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
}

export enum TranscribeSubjectEnum {
  GENERAL = 1,
  FINANCE = 2,
  MEDICAL = 3,
  AUTOMOTIVE = 4,
}

export enum PointOfViewEnum {
  FIRST_PERSON = 1,
  THIRD_PERSON = 3,
}

export enum ArticleTypeEnum {
  NONE = 0, // AI determined // DEFAULT
  SUMMARY = 1,
  LONG_SUMMARY = 2,
  NEWS = 3,
  LISTICLE = 4,
  TUTORIAL = 5,
  OPINION = 6,
  INTERVIEW = 7,
  ACTION_STEPS = 8,
  PERSONAL_STORIES = 9, // ANECDOTE
  PRODUCT_REVIEW = 10,
  GUIDE = 11, // ULTIMATE GUIDE
  CASE_STUDY = 12,
}

export const RunStatusMap = {
  [TemplateStatusEnum.DRAFT]: "Draft",
  [TemplateStatusEnum.PENDING]: "Pending",
  [TemplateStatusEnum.QUEUED]: "Queued",
  [TemplateStatusEnum.COMPLETED]: "Completed",
  [TemplateStatusEnum.AUTO]: "Auto",
  [TemplateStatusEnum.PAUSED]: "Paused",
  [TemplateStatusEnum.STOPPED]: "Stopped",
  [TemplateStatusEnum.ARCHIVED]: "Archived",
};

export const RunStatusColorMap = {
  [TemplateStatusEnum.DRAFT]: "#d4d4d8",
  [TemplateStatusEnum.PENDING]: "#FFEB3B",
  [TemplateStatusEnum.QUEUED]: "#D68F29",
  [TemplateStatusEnum.COMPLETED]: "#22C55E",
  [TemplateStatusEnum.AUTO]: "#2563EB",
  [TemplateStatusEnum.PAUSED]: "#ef4444",
  [TemplateStatusEnum.STOPPED]: "#DC2626",
  [TemplateStatusEnum.ARCHIVED]: "#888888",
};

export const BrandPersonaMap = {
  [BrandPersonaEnum.PROFESSIONAL]: "Professional",
  [BrandPersonaEnum.EXPERT]: "Expert",
  [BrandPersonaEnum.PHILOSOPHER]: "Philosopher",
  [BrandPersonaEnum.FRIEND]: "Friend",
  [BrandPersonaEnum.ENTERTAINER]: "Entertainer",
  [BrandPersonaEnum.SAGE]: "Sage",
  [BrandPersonaEnum.REBEL]: "Rebel",
  [BrandPersonaEnum.CAREGIVER]: "Caregiver",
};

export const BrandVoiceMap = {
  [BrandVoiceEnum.PROFESSIONAL]: "Professional",
  [BrandVoiceEnum.CASUAL]: "Casual",
  [BrandVoiceEnum.ENTHUSIASTIC]: "Enthusiastic",
  [BrandVoiceEnum.STRAIGHTFORWARD]: "Straightforward",
  [BrandVoiceEnum.WITTY]: "Witty",
  [BrandVoiceEnum.EMPOWERING]: "Empowering",
  [BrandVoiceEnum.INTELLECTUAL]: "Intellectual",
  [BrandVoiceEnum.MOTIVATIONAL]: "Motivational",
};

export const BrandToneMap = {
  [BrandToneEnum.INFORMATIVE]: "Informative",
  [BrandToneEnum.PERSUASIVE]: "Persuasive",
  [BrandToneEnum.PLAYFUL]: "Playful",
  [BrandToneEnum.FRIENDLY]: "Friendly",
  [BrandToneEnum.INSPIRING]: "Inspiring",
  [BrandToneEnum.ASPIRATIONAL]: "Aspirational",
  [BrandToneEnum.PASSIONATE]: "Passionate",
};

export const AutomationDays = [
  AutomationDaysEnum.MON,
  AutomationDaysEnum.TUE,
  AutomationDaysEnum.WED,
  AutomationDaysEnum.THUR,
  AutomationDaysEnum.FRI,
  AutomationDaysEnum.SAT,
  AutomationDaysEnum.SUN,
];

export const AutomationDaysMap = {
  [AutomationDaysEnum.MON]: "M",
  [AutomationDaysEnum.TUE]: "Tu",
  [AutomationDaysEnum.WED]: "W",
  [AutomationDaysEnum.THUR]: "Th",
  [AutomationDaysEnum.FRI]: "F",
  [AutomationDaysEnum.SAT]: "Sa",
  [AutomationDaysEnum.SUN]: "Su",
};
