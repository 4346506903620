import { EngineEnum } from "../enums/engine";
import {
  BrandPersonaEnum,
  BrandToneEnum,
  BrandVoiceEnum,
  FaqQuestionPrefixEnum,
  ImageSizeEnum,
  ImageStyleEnum,
  ImageToneEnum,
  PointOfViewEnum,
  SourceEnum,
  TemplateStatusEnum,
  TranscribeSubjectEnum,
} from "../enums/template";

export class Template {
  template_id: string;
  name: string;
  source_id: SourceEnum;
  template_status_id: TemplateStatusEnum;
  bio: string;
  media_author_name: string;
  completed_at: string;
  media_url: string | null;
  s3_media_url: string;
  include_intro: boolean;
  article_length: number | null;
  temperature: number;
  point_of_view_id: PointOfViewEnum | null;
  include_web_embed: boolean;
  brand_persona_id: BrandPersonaEnum | null;
  brand_voice_id: BrandVoiceEnum | null;
  brand_tone_id: BrandToneEnum | null;
  include_image: boolean;
  image_size_id: ImageSizeEnum | null;
  image_style_id: ImageStyleEnum | null;
  image_tone_id: ImageToneEnum | null;
  include_faq: boolean;
  faq_count: number | null;
  faq_question_prefix_id: FaqQuestionPrefixEnum | null;
  is_auto_enabled: boolean;
  profile_url: string | null;
  destination_id: string | null;
  destination_id_list: string | string[] | null;
  auto_days: number[] | null;
  auto_time1: string | null;
  auto_time2: string | null;
  post_url: string;
  post_media_url: string;
  username: string | null;
  external_user_id: string | null;
  app_password: string;
  post_status: string;
  post_author_id: string;
  post_category_id: string;
  transcribe_subject_id: TranscribeSubjectEnum;
  timezone: string;
  article_count: number;
  include_credits: boolean;
  article_type_id: number;
  engine_id: EngineEnum | null;
  old_destination_id: string | null;
  is_speaker_the_author: boolean | null;
  speaker_name: string | null;
  speaker_about: string | null;
  additional_context: string | null;

  // frontend only fields
  upload_media_url: string | null;

  constructor({
    template_id,
    name,
    source_id,
    template_status_id,
    bio,
    media_author_name,
    completed_at,
    media_url,
    s3_media_url,
    include_intro,
    article_length,
    temperature,
    point_of_view_id,
    include_web_embed,
    brand_persona_id,
    brand_voice_id,
    brand_tone_id,
    include_image,
    image_size_id,
    image_style_id,
    image_tone_id,
    include_faq,
    faq_count,
    faq_question_prefix_id,
    is_auto_enabled,
    profile_url,
    destination_id,
    auto_days,
    auto_time1,
    auto_time2,
    post_url,
    post_media_url,
    username,
    app_password,
    post_status,
    post_author_id,
    post_category_id,
    transcribe_subject_id,
    timezone,
    article_count,
    destination_id_list,
    include_credits,
    article_type_id,
    engine_id,
    old_destination_id,
    is_speaker_the_author,
    speaker_name,
    speaker_about,
    additional_context,
    upload_media_url,
    external_user_id,
  }: any) {
    this.template_id = template_id;
    this.name = name;
    this.source_id = source_id;
    this.bio = bio;
    this.media_author_name = media_author_name;
    this.template_status_id = template_status_id;
    this.completed_at = completed_at;
    this.media_url = media_url;
    this.s3_media_url = s3_media_url;
    this.include_intro = include_intro;
    this.article_length = article_length;
    this.temperature = temperature;
    this.point_of_view_id = point_of_view_id;
    this.include_web_embed = include_web_embed;
    this.brand_persona_id = brand_persona_id;
    this.brand_voice_id = brand_voice_id;
    this.brand_tone_id = brand_tone_id;
    this.include_image = include_image;
    this.image_size_id = image_size_id;
    this.image_style_id = image_style_id;
    this.image_tone_id = image_tone_id;
    this.include_faq = include_faq;
    this.faq_count = faq_count;
    this.faq_question_prefix_id = faq_question_prefix_id;
    this.is_auto_enabled = is_auto_enabled;
    this.profile_url = profile_url;
    this.auto_days = auto_days;
    this.auto_time1 = auto_time1;
    this.auto_time2 = auto_time2;
    this.post_url = post_url;
    this.post_media_url = post_media_url;
    this.username = username;
    this.app_password = app_password;
    this.post_status = post_status;
    this.post_author_id = post_author_id;
    this.post_category_id = post_category_id;
    this.transcribe_subject_id = transcribe_subject_id;
    this.timezone = timezone;
    this.article_count = article_count;
    this.destination_id = destination_id;
    this.destination_id_list = destination_id_list;
    this.include_credits = include_credits;
    this.article_type_id = article_type_id;
    this.engine_id = engine_id;
    this.old_destination_id = old_destination_id;
    this.is_speaker_the_author = is_speaker_the_author;
    this.speaker_name = speaker_name;
    this.speaker_about = speaker_about;
    this.additional_context = additional_context;
    this.upload_media_url = upload_media_url;
    this.external_user_id = external_user_id;
  }
}
