import { FormikErrors } from "formik";
import React from "react";
import InfoTooltip from "../../../components/InfoTooltip";
import Input from "../../../components/Input";
import RadioGroup, { Option } from "../../../components/RadioGroup";
import TextArea from "../../../components/TextArea";
import {
  ArticleTypeEnum,
  TranscribeSubjectEnum,
} from "../../../enums/template";
import { Template } from "../../../models";
import { getRandomCharacterQuote } from "../../../utils";
import ArticleType from "./ArticleType";
import SwitchContainer from "./common/SwitchContainer";
import TemplateContainer from "./common/TemplateContainer";

type AdditionalSettingsProps = {
  values: Partial<Template>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<any>>;
};

export const transcribeSubjectOptions = [
  {
    id: TranscribeSubjectEnum.GENERAL,
    name: "General",
  },
  {
    id: TranscribeSubjectEnum.FINANCE,
    name: "Finance",
  },
  {
    id: TranscribeSubjectEnum.MEDICAL,
    name: "Medical",
  },
  {
    id: TranscribeSubjectEnum.AUTOMOTIVE,
    name: "Automotive",
  },
];

const AdditionalSettings = ({
  values,
  setFieldValue,
}: AdditionalSettingsProps) => {
  return (
    <div className="space-y-6 animate-fadeIn">
      <TemplateContainer>
        <div>
          <SwitchContainer
            title="Include Credits"
            subTitle=""
            tooltip='This will add "Article by", "Original author" and "Source Link" to the bottom of the article.'
            enabled={!!values?.include_credits}
            setEnabled={() =>
              setFieldValue("include_credits", !values?.include_credits)
            }
          />
        </div>
        {values?.include_credits && (
          <>
            <div className="mt-6">
              <Input
                label="Article By"
                name="bio"
                value={values?.bio!}
                placeholder={getRandomCharacterQuote()}
                tooltip='This will add "Article by" and whatever you put in the field to the bottom of the article.'
                onChange={({ target: { value } }) =>
                  setFieldValue("bio", value)
                }
              />
            </div>
            {/* <Input
              label="Original Content Author (if different than above)"
              name="media_author_name"
              value={values?.media_author_name!}
              placeholder='Name of the original author of the content if different than the "Article By"'
              tooltip='This will add "Original author" and whatever you put is this field to the bottom of the article.'
              onChange={({ target: { value } }) =>
                setFieldValue("media_author_name", value)
              }
            /> */}
          </>
        )}
      </TemplateContainer>
      <TemplateContainer className="flex flex-col gap-6">
        <ArticleType
          articleTypeId={values?.article_type_id || ArticleTypeEnum.NONE}
          onSelect={(articleTypeId: ArticleTypeEnum) => {
            setFieldValue("article_type_id", articleTypeId);
          }}
          values={values}
        />
        <TextArea
          label="Additional context"
          name="speaker_about"
          value={values?.additional_context ?? ""}
          tooltip="Add any additional information about the source or channel which will be helpful to the AI for content generation."
          placeholder="example: Jane Doe is speaking in a podcast about the importance of mental health in fitness."
          maxLength={500}
          maxHeight={150}
          showCharacterCount={true}
          onChange={({ target: { value } }) =>
            setFieldValue("additional_context", value)
          }
          onBlur={({ target: { value } }) => {
            setFieldValue("additional_context", value.trim());
          }}
        />
        <div className="w-max">
          <h3 className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
            Primary Subject of Your Content
            <InfoTooltip
              id="subject"
              tooltip="Use General for most cases. If your content is specific to finance, medical, or automotive then selecting the appropriate option will aid in the vocabulary translation."
            />
          </h3>
          <RadioGroup
            options={transcribeSubjectOptions}
            name="transcribe_subject_id"
            selected={transcribeSubjectOptions.find(
              ({ id }) => id === values?.transcribe_subject_id
            )}
            onClick={({ id }: Option) =>
              setFieldValue("transcribe_subject_id", id)
            }
          />
        </div>
        <div>
          <label
            htmlFor="temperature"
            className="mb-1 flex items-center gap-2 uppercase text-xs tracking-wide font-medium leading-6 text-gray-900"
          >
            Level of Creativity
            <InfoTooltip
              id="creativity"
              tooltip="The level of creativity will somewhat control the randomness of the text generated. A minimal creativity makes the output more deterministic and focused. A high creativity results in more diverse and creative output which can also be, at times, unpredictable."
            />
          </label>
          <div className="relative mb-6 w-96">
            <input
              id="labels-range-input"
              type="range"
              step="0.1"
              min="0.2"
              max="0.9"
              name="temperature"
              value={values?.temperature}
              onChange={({ target: { value } }) =>
                setFieldValue("temperature", value)
              }
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            />
            <span className="text-sm text-gray-500 absolute start-0 -bottom-6">
              Minimal
            </span>
            <span className="text-sm text-gray-500 absolute start-1/3 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
              Basic
            </span>
            <span className="text-sm text-gray-500 absolute start-2/3 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
              Moderate
            </span>
            <span className="text-sm text-gray-500 absolute end-0 -bottom-6">
              High
            </span>
          </div>
        </div>
      </TemplateContainer>
    </div>
  );
};

export default AdditionalSettings;
